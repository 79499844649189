import * as React from 'react';

import * as Yup from 'yup';
import { Field, FieldProps } from 'formik';

import { useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { LinkType, link } from '../StaticPages/utils';

import { SpacialHeadlines } from './SpacialHeadlines';

import { TextField } from '@app/components/TextField/TextField';
import { FormTemplate, IMessage, SuccessMessage } from '@app/pages/Forms/FormTemplate';
import { simpleBoolSchema, simpleStringSchema } from '@app/services/validationSchemas';
import { TextAreaField } from '@app/components/TextField/TextAreaField';
import { request } from '@app/services/api';
import { NotificationType } from '@app/components/Notification/Notification';

import { RootStore } from '@app/store';

import { formatPhone } from '@app/services/phone';

interface FriendForwardFormValues {
	name: string;
	email: string;
	friendEmail: string;
	note: string;
	isUSEmail: boolean;
	agreeSubmittingTerms: boolean;
}

const initialValues: FriendForwardFormValues = {
	name: '',
	email: '',
	friendEmail: '',
	note: '',
	isUSEmail: false,
	agreeSubmittingTerms: false,
};

const validationSchema = Yup.object().shape({
	name: simpleStringSchema,
	email: Yup.string()
		.email('Invalid email format')
		.notOneOf([Yup.ref('friendEmail')], 'The email address should`t be the same')
		.required('This field is required!'),
	friendEmail: Yup.string()
		.email('Invalid email format')
		.notOneOf([Yup.ref('email')], 'The email address should`t be the same')
		.required('This field is required!'),
	isUSEmail: simpleBoolSchema,
	agreeSubmittingTerms: simpleBoolSchema,
});

const InnerForm = (): JSX.Element => (
	<div className="col-12">
		<div className="layout-container">
			<Field name="name">
				{({ field, form }: FieldProps<string, FriendForwardFormValues>) => (
					<div className="col-12 col-sm-4 form-group">
						<TextField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Your Name*"

							text={form.touched.name ? form.errors.name : undefined}
							error={Boolean(form.errors.name && form.touched.name)}
						/>
					</div>
				)}
			</Field>
			<Field name="email">
				{({ field, form }: FieldProps<string, FriendForwardFormValues>) => (
					<div className="col-12 col-sm-4 form-group">
						<TextField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Your Email Address*"
							inputProps={{ type: 'email' }}

							text={form.touched.email ? form.errors.email : undefined}
							error={Boolean(form.errors.email && form.touched.email)}
						/>
					</div>
				)}
			</Field>
			<Field name="friendEmail">
				{({ field, form }: FieldProps<string, FriendForwardFormValues>) => (
					<div className="col-12 col-sm-4 form-group">
						<TextField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Friend's Email Address*"
							inputProps={{ type: 'email' }}

							text={form.touched.friendEmail ? form.errors.friendEmail : undefined}
							error={Boolean(form.errors.friendEmail && form.touched.friendEmail)}
						/>
					</div>
				)}
			</Field>
			<Field name="note">
				{({ field, form }: FieldProps<number, FriendForwardFormValues>) => (
					<div className="col-12 form-group">
						<TextAreaField
							value={field.value}
							onChange={(value: string) => form.setFieldValue(field.name, value, false)}
							onBlur={field.onBlur}

							title="Your Message"

							text={form.touched.note ? form.errors.note : undefined}
							error={Boolean(form.errors.note && form.touched.note)}
						/>
					</div>
				)}
			</Field>
		</div>
	</div>
);

const SuccessExtraMessages: React.FC = () => {
	const header = useSelector((store: RootStore) => store.header.item);
	const phone = header?.phone;

	return (
		<p className="form__subtitle" style={{ marginTop: 25, width: '80%' }}>
			One of our travel specialists will contact you shortly to review your request.
			If you have any questions and would like immediate help, please call us
			{
				phone ? (
					<>
						at {link(formatPhone(phone), LinkType.Phone)}
					</>
				) : null
			}
		</p>
	);
};

export const FriendForward = () => {
	const [message, setMessage] = React.useState<IMessage | undefined>(undefined);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const specialsId = params.get('specialsid');

	return (
		<>{!message || message.type !== NotificationType.Success ? (
			<SpacialHeadlines specialsId={specialsId} />
		) : null}<FormTemplate
			title=""
			innerForm={<InnerForm />}
			initialValues={initialValues}
			validationSchema={validationSchema}
			extraCheckbox={{
				fieldName: 'isUSEmail',
				label: 'I certify the above email address(es) belongs to someone in the United States',
			}}
			onSubmit={(values: FriendForwardFormValues, { setSubmitting, resetForm }) => {
				request('api/bridge/v1/FriendForward-form', {
					method: 'POST',
					data: {
						name: values.name,
						email: values.email,
						friendEmail: values.friendEmail,
						note: values.note,
						isUSEmail: Boolean(values.isUSEmail),
						agreeSubmittingTerms: Boolean(values.agreeSubmittingTerms),
						specialsId,
					},
				})
					.then((response) => {
						console.log(response);
						resetForm();
						setMessage({
							type: NotificationType.Success,
							content: (
								<SuccessMessage
									title="Thank you!"
									subtitle="This offer has been sent."
									extra={<SuccessExtraMessages />}
								/>
							),
						});
					})
					.catch((error: Error) => {
						console.warn('Failed to Send details: ', error.message);
						setMessage({ type: NotificationType.Error, content: 'Failed to Send details' });
					})
					.finally(() => setSubmitting(false));
			}}
			message={message}
		/>
		</>
	);
};
