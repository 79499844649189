import * as React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';

import { Header } from '@app/components/Header/Header';
import { Footer } from '@app/components/Footer/Footer';
import { Chat } from '@app/components/Chat/Chat';
import { CookiesAlert } from '@app/components/CookiesAlert/CookiesAlert';
import { useAoTracker } from '@app/hooks/useAoTracker';
import { BurgerContext } from '@app/services/contexts';
import { useBurger } from '@app/hooks/useBurger';

interface LayoutProps {
	children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
	useAoTracker(); // Needs to be placed under <Router />
	const context = useBurger();
	const location = useLocation();
	const hideHeaderFooter = location.pathname === '/airGateway';

	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.app.recaptchaKey}>
			<BurgerContext.Provider value={context}>
				{!hideHeaderFooter && <Header />}
				{props.children}
				{!hideHeaderFooter && <Footer />}
				{!hideHeaderFooter && <Chat />}
				<CookiesAlert />
			</BurgerContext.Provider>
		</GoogleReCaptchaProvider>
	);
};
