import * as React from 'react';
import { Link } from 'react-router-dom';

import {
	Form,
	Formik,
	Field,
	FieldProps,
} from 'formik';
import { OptGroup } from 'rc-select';

import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { Icon } from '@app/image/icon';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';

import { useOdesseus } from '@app/odesseus/useOdesseus';
import {
	OdesseusFilterKeys,
} from '@app/odesseus/types/Filters';
import { Nullable } from '@app/objects/Utility';
import { OdyManager } from '@app/odesseus/OdyManager';

import {
	OdesseusFilterRecord,
	OdesseusSearchFormValues,
	OdesseusSelectOption,
	OdesseusSelectOptionGroup,
} from '@app/odesseus/types/Filters/Odesseus';
import { Range } from '@app/objects/Filters/Range';
import {
	addOdesseusRanges,
	addOdesseusValues,
	getSearchURL,
} from '@app/odesseus/FilterServices';

import '@app/scss/layout.scss';

const initialValues: OdesseusSearchFormValues = {
	[OdesseusFilterKeys.DURATION]: [],
	[OdesseusFilterKeys.DEPARTUREDATE]: [],
	[OdesseusFilterKeys.DESTINATION]: [],
	[OdesseusFilterKeys.CRUISELINE]: [],
	[OdesseusFilterKeys.SHIP]: [],
	[OdesseusFilterKeys.DEPARTUREPORT]: [],
};

export const SearchCruises: React.FC = () => {
	const [filters, setFilters] = React.useState<Array<OdesseusFilterRecord>>([]);
	const [lastChange, setLastChange] = React.useState<Nullable<OdesseusFilterKeys>>(() => null);
	const { options, loading } = useOdesseus(filters, lastChange);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: OdesseusSearchFormValues) => {
				window.open(getSearchURL(values, options));
			}}
		>
			{
				(formikBag) => (
					<Form id="cruises-search-form" className="layout-container content-halign_center">
						<div className="col-12 col-lg-12 col-xl-9">
							<div className="layout-container">
								<Field name={OdesseusFilterKeys.DESTINATION}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Destination/River"
												Icon={Icon.Location}
												tabIndex={1}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.DESTINATION, values));
													setLastChange(OdesseusFilterKeys.DESTINATION);
												}}
											>
												{
													options?.destinations.map((option: OdesseusSelectOption) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.DEPARTUREPORT}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Departure Port"
												Icon={Icon.Anchor}
												tabIndex={2}
												loading={loading}
												multiple
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.DEPARTUREPORT, values));
													setLastChange(OdesseusFilterKeys.DEPARTUREPORT);
												}}
											>
												{
													options?.ports.map((option: OdesseusSelectOption) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.CRUISELINE}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Cruise Line/Tour Operator"
												Icon={Icon.Helm}
												tabIndex={4}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.CRUISELINE, values));
													setLastChange(OdesseusFilterKeys.CRUISELINE);
												}}
											>
												{
													options?.cruiselines.map((option: OdesseusSelectOption) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>

								<Field name={OdesseusFilterKeys.SHIP}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Ships"
												Icon={Icon.Ship}
												tabIndex={4}
												multiple
												loading={loading}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													setFilters(addOdesseusValues(filters, OdesseusFilterKeys.SHIP, values));
													setLastChange(OdesseusFilterKeys.SHIP);
												}}
											>
												{
													options?.ships.map((item: OdesseusSelectOptionGroup) => (
														<OptGroup label={item.name} key={item.name}>
															{item.options.map((option: OdesseusSelectOption) => (
																<Option value={option.value} key={option.value}>
																	{option.name}
																</Option>
															))}
														</OptGroup>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.DEPARTUREDATE}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Travel Dates"
												Icon={Icon.Calendar}
												tabIndex={5}
												multiple
												loading={loading}
												maxTagCount={6}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													const ranges = (
														values.map(
															(item: number) =>
																options.dates.find(
																	(date: OdesseusSelectOption<Range<number>>) => date.value === item,
																)?.raw,
														).filter((item: Range<number> | undefined) => item) as Array<Range<number>>
													).map((item: Range<number>) => ({
														from: OdyManager.toDateString(item.from),
														to: item.to ? OdyManager.toDateString(item.to) : undefined,
													}));
													setFilters(addOdesseusRanges(filters, OdesseusFilterKeys.DEPARTUREDATE, ranges));
													setLastChange(OdesseusFilterKeys.DEPARTUREDATE);
												}}
											>
												{
													options?.dates.map((option: OdesseusSelectOption<Range<number>>) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
								<Field name={OdesseusFilterKeys.DURATION}>
									{({ field, form }: FieldProps) => (
										<div className="col-12 col-lg-6 col-xl-4 content-valign_bottom">
											<Select<number>
												placeholder="Length"
												Icon={Icon.Moon}
												tabIndex={6}
												multiple
												loading={loading}
												value={field.value}
												onChange={(values: Array<number>) => {
													form.setFieldValue(field.name, values);
													const ranges = (
														values.map(
															(item: number) =>
																options.durations.find(
																	(date: OdesseusSelectOption<Range<number>>) => date.value === item,
																)?.raw,
														).filter((item: Range<number> | undefined) => item) as Array<Range<number>>
													).map((item: Range<number>) => ({
														from: item.from.toString(),
														to: item.to ? item.to.toString() : undefined,
													}));
													setFilters(addOdesseusRanges(filters, OdesseusFilterKeys.DURATION, ranges));
													setLastChange(OdesseusFilterKeys.DURATION);
												}}
											>
												{
													options?.durations.map((option: OdesseusSelectOption<Range<number>>) => (
														<Option key={option.value} value={option.value}>
															{option.name}
														</Option>
													))
												}
											</Select>
										</div>
									)}
								</Field>
							</div>
						</div>
						<div className="button-container col-12 col-lg-12 col-xl-3">
							<div className="layout-container content-halign_center">
								<div className="col-12">
									<Link
										to="https://www.sigtn.com/clients/emarketing/intro.cfm?agency_id=2954"
										className="search-card__covid_bottom font-family_accent font-weight_medium text-size_small"
										target="_blank"
									>
										Enter Contest
									</Link>
								</div>
								<div className="col-12 col-lg-6 col-xl-12">
									<Button
										style={{ width: '100%' }}
										className="uppercase form-group"
										variant={ButtonVariant.Filled}
										tabIndex={7}
										action={ActionMode.InternalLink}
										link="search"
										onClick={formikBag.handleSubmit}
									>
										Search cruises
									</Button>
								</div>
								<div className="col-12 col-lg-6 col-xl-12">
									<Button
										variant={ButtonVariant.Underlined}
										className="uppercase form-group"
										tabIndex={8}
										action={ActionMode.Button}
										onClick={() => {
											formikBag.resetForm();
											setFilters([]);
											setLastChange(null);
										}}
									>
										Reset all
									</Button>
								</div>
							</div>
						</div>
					</Form>
				)
			}
		</Formik>
	);
};
