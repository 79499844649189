import * as React from 'react';

import Tabs from 'rc-tabs';

import { SearchCruises } from '@app/pages/MainPage/SearchCard/TabsSearchCard/SearchCruises';
import { SearchPackages } from '@app/pages/MainPage/SearchCard/TabsSearchCard/SearchPackages';
import { SearchHotel } from '@app/pages/MainPage/SearchCard/TabsSearchCard/SearchHotel';
import { TrustBox } from '@app/pages/MainPage/SearchCard/TrustBox';
import { Icon } from '@app/image/icon';

import 'rc-tabs/assets/index.css';
import '@app/scss/pages/main/searchCard.scss';

export enum ActiveTabKey {
	Cruises = 'cruises',
	Packages = 'packages',
	Hotels = 'hotels',
}

const tabs = (activeKey: ActiveTabKey) => ([
	{
		label: 'Cruises',
		key: ActiveTabKey.Cruises,
		children: <SearchCruises />,
	},
	{
		label: 'Cruise Packages & Tours',
		key: ActiveTabKey.Packages,
		children: <SearchPackages />,
	},
	{
		label: 'Hotels',
		key: ActiveTabKey.Hotels,
		children: <SearchHotel activeKey={activeKey} />,
	},
]);

export const extraContent = (
	<a
		href="https://www.sigtn.com/clients/emarketing/intro.cfm?agency_id=2954"
		className="search-card__covid font-family_accent font-weight_medium text-size_small"
		target="_blank"
	>
		Enter Contest
	</a>
);

export const SearchCard: React.FC = () => {
	const [activeKey, setActiveKey] = React.useState<ActiveTabKey>(ActiveTabKey.Cruises);

	return (
		<div className="search-card font-family_primary font-weight_medium text-size_normal">
			<Tabs
				items={tabs(activeKey)}
				moreIcon={<Icon.Ellipsis />}
				tabBarExtraContent={extraContent}
				onChange={(key: string) => setActiveKey(key as ActiveTabKey)}
			/>

			<TrustBox>
				{(ref: React.MutableRefObject<null>, loader: React.ReactElement) => (
					<div
						ref={ref}
						className="trustpilot-widget trust-box"
						data-locale="en-US"
						data-template-id="5406e65db0d04a09e042d5fc"
						data-businessunit-id="6037f8ef3217ab0001f8e69e"
						data-style-height="28px"
						data-style-width="100%"
						data-theme="light"
						data-font-family="Fira Sans"
						data-text-color="#090e2c"
					>
						<a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener noreferrer">{loader}</a>
					</div>
				)}
			</TrustBox>
		</div>
	);
};
