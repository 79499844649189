import * as React from 'react';

import CoreSelect, { BaseSelectRef } from 'rc-select';
import clsx from 'clsx';

import {
	AutoCompleteProps,
} from '@app/components/AutoComplete/AutoCompleteProps';

import '@app/scss/components/select.scss';
import { useDebounce } from '@app/hooks/useDebounce';
import { TIcon, Nullable } from '@app/objects/Utility';

import { Icon } from '@image/icon';

const renderIcon = (
	Icon: TIcon,
	active: boolean,
) => (
	<div className="content-valign_center">
		<Icon className={clsx('select-component__icon', active && 'select-component__icon_active')} />
	</div>
);

export const AutoComplete = (props: AutoCompleteProps) => {
	const ref = React.useRef<BaseSelectRef>(null);
	const [focus, setFocus] = React.useState<boolean>(() => false);
	const hasValue = props.value;
	const active = Boolean(hasValue || focus);

	const debounce = useDebounce<string>((value: Nullable<string>) => {
		const str = value ?? '';
		props.onSearch?.(str);
	}, 750);

	return (
		<div
			className={clsx('select-component-container', props.error && 'select-component-container_error')}
		>
			<div
				className={clsx(
					'select-component font-family_primary font-weight_regular text-size_medium',
					focus && 'select-component_focused',
				)}
				onClick={() => ref.current?.focus()}
			>
				{props.Icon ? renderIcon(props.Icon, active) : null}
				<div className="select-component__control-block is-relative">
					<CoreSelect
						value={props.value}
						onChange={(value: Nullable<string>) => {
							props.onChange?.(value);
						}}
						open={focus}

						disabled={props.disabled}
						loading={props.loading}
						allowClear
						showSearch

						prefixCls="sc-core"
						className={clsx('font-family_primary font-weight_medium text-size_medium', props.className)}
						dropdownClassName="select-component__dropdown font-family_primary text-size_small"
						style={props.style}

						onSelect={props.onSelect}
						onDeselect={props.onDeselect}
						onSearch={(query: string) => {
							if (!focus) return;

							props.onChange?.(query);
							debounce(query);
						}}
						onBlur={() => {
							setFocus(false);
							props.onBlur?.();
						}}
						onFocus={() => {
							setFocus(true);
							props.onFocus?.();
						}}

						ref={ref}
						notFoundContent={null}

						clearIcon={<Icon.Clear className="select-component__icon" />}
						menuItemSelectedIcon={<Icon.Check className="select-component__icon" />}
						searchValue={props.value ?? ''}
					>
						{props.children}
					</CoreSelect>
					<div
						className={clsx(
							'select-component__control-block__placeholder',
							active && 'select-component__control-block__placeholder_top text-size_extra-small',
						)}
					>
						{props.placeholder}
					</div>
				</div>
			</div>
			<div className="select-component-container__text font-family_primary font-weight_light text-size_extra-small">
				{props.text}
			</div>
		</div>
	);
};
