import React, { useEffect, useState } from 'react';

import '@app/scss/pages/search/AirGateway.scss';

import { AxiosResponse } from 'axios';

import { useLocation } from 'react-router-dom';

import { httpModules } from '@app/helpers/http.module';

export interface IGatewayData {
	GROUPMASTERID: number;
	GATEWAY: string;
	GATEWAYCITY: string;
	GATEWAYSTATECOUNTRY: string;
	RATE: string;
	AIRTAX: number;
}

interface IGatewayDataInfo {
	DATA: Array<IGatewayData>;
}

export const AirGateway: React.FC = () => {
	const [airGatewayData, setairGatewayData] = useState<Array<IGatewayData>>([]);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const GroupmasterId = params.get('groupmasterId');
	useEffect(() => {
		httpModules
			.get<IGatewayDataInfo>(`/specials/dspAir.cfm?groupmasterId=${GroupmasterId}`)
			.then((response: AxiosResponse<IGatewayDataInfo>) => {
				const merged: IGatewayDataInfo = {
					DATA: response.data.DATA,
				};
				setairGatewayData(merged.DATA);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className="air-gate-way">
			<div className="container">
				<div className="scroll-tables">
					<div className="table-hed">
						<h1>AIR IS AVAILABLE FROM SELECT GATEWAY AS SHOWN BELOW :</h1>
					</div>
					<table>
						<thead>
							<tr>
								<th className="align-left">Gateway</th>
								<th>Gateway Code</th>
								<th>AirFare*</th>
							</tr>
						</thead>
						<tbody>
							{
								airGatewayData && airGatewayData.map((value) => {
									const concatenatedString = `${value.GATEWAYCITY} - ${value.GATEWAYSTATECOUNTRY}`;

									return (
										<tr key={value.GATEWAYSTATECOUNTRY}>
											<td className="align-left">{concatenatedString}</td>
											<td>{value.GATEWAY}</td>
											<td>{value.RATE}</td>
										</tr>
									);
								})
							}
						</tbody>
					</table>
				</div>
				<p className="air_prices">
					Air Prices above include taxes, but do not include transfers, fuel surcharges,
					or other fees unless specifically stated otherwise.
				</p>
			</div>
		</div>
	);
};
